import React, { useState, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { ScaleLoader } from 'react-spinners';
import axios from 'axios';

const placeHolderEgs = ["cats", "Britney songs", "plate tectonics",
"amino acids", "stamps of the world", "80s video games"]
const server = "quizanythingbackend.theo.io"
//const server = "localhost:8000"

function App() {



  const [didTimeout, setDidTimeout] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [questionsAttempted, setQuestionsAttempted] = useState(0); // Add this state variable

  const [score, setScore] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [questionBeingAppealed, setQuestionBeingAppealed] = useState(null);
  const [appealSuccess, setAppealSuccess] = useState(null);
  const [showAnswerFeedback, setShowAnswerFeedback] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState('');
  const [socket, setSocket] = useState(null);
  const [title, setTitle] = useState('');
  const [titleDelayHappened, setTitleDelayHappened] = useState(false);
  const [placeholder, setPlaceholder] = useState('e.g. cats');

  useEffect(() => {
    setInterval(() => {
      setPlaceholder("e.g. " + placeHolderEgs[Math.floor(Math.random() * placeHolderEgs.length)])
    }, 5000)
  }, [])

  


  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const obj = JSON.parse(event.data);
        if (obj.title){
          setTitle(
            // remove any double quotes
            obj.title.replaceAll('"', '').replaceAll(".", '')
            
            );
            setTimeout(() => {
              setTitleDelayHappened(true);
            }, 2500);
          return; 
        }
        
       

        setQuestions((prevQuestions) => prevQuestions.length ===10 ? prevQuestions : [...prevQuestions, obj]);
        setIsLoading("finished");
      };
    }
  }, [socket]);

  const [difficulty, setDifficulty] = useState('medium'); // Add this state variable

  const handleTopicSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //const newSocket = new WebSocket(`wss://quizanythingbackend.theo.io/quiz/${topic}/${difficulty}`); // Send difficulty to WebSocket
    const newSocket = new WebSocket(`wss://${server}/quiz/${topic}/${difficulty}`); // Send difficulty to WebSocket
    setSocket(newSocket);
    setTimeout(() => {
      setDidTimeout(true);
    }
    , 12000);

  };

  const handleAppeal = async () => {
    setQuestionBeingAppealed(currentQuestion);
    setAppealSuccess(null);
    try {
      const response = await axios.post(`//${server}/appeal`, {
        question: questions[currentQuestion].question,
        answer: questions[currentQuestion].responses[selectedAnswer],
      });
  
      if (response.data) {
        setScore(score + 1);
        setAppealSuccess(true);
      } else {
        setAppealSuccess(false);
      }
    } catch (error) {
      console.error('Error during appeal:', error);
      alert('There was an error processing your appeal. Sorry.');
    }
  };

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    if (questions[currentQuestion].answer === answer) {
      setScore(score + 1);
    }
    setQuestionsAttempted(questionsAttempted + 1);
    setShowAnswerFeedback(true);
    if (questions[currentQuestion].answer === answer) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 2000);
    }
  };

  const handleNextQuestion = () => {
    
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    }
    setSelectedAnswer(null);
    setShowAnswerFeedback(false);
    
  };

  const handleRestart = () => {
    window.location.reload(); // Refresh the page to restart the quiz
  };

  let successPercentage = (score / questionsAttempted) * 100; // Calculate the success percentage
  // if is NAN, set it to 0
  if (isNaN(successPercentage)) {
    successPercentage = 0;
  }


  if (questions.length === 0 || isLoading===true || !titleDelayHappened) {
    return (
      <div className="flex flex-col items-center justify-center h-screen px-2">
        {!isLoading  && ( // Conditionally render the form elements
          <form onSubmit={handleTopicSubmit} className="flex flex-col items-center">
            <label htmlFor="topic" className="text-2xl mb-4">
              Enter a topic:
            </label>
            <input
              type="text"
              id="topic"
              value={topic}
              placeholder={placeholder}
              // disable autocomplete
              autoComplete="off"
              onChange={(e) => setTopic(e.target.value)}
              className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
           
            <select
              id="difficulty"
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              className=" mt-3 shadow text-sm  border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              
              <option value="very easy">Very easy</option>
              <option value="easy">Easy</option>
              <option value="medium">Medium difficulty</option>
              <option value="hard">Hard</option>
              <option value="very hard">Fiendish</option>
            </select>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Start Quiz
            </button>

            <div className="mt-24 text-gray-500 opacity-70 hover:opacity-90">
            <a href="https://twitter.com/theosanderson?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @theosanderson</a>
            </div>
          </form>
        )}
        {isLoading && (
          <div className="mt-8 text-center">
            <ScaleLoader color="#123abc" loading={true} size={150} 
            />
            {title &&
            <h1 className="text-2xl font-bold mb-4 text-center">{title}</h1>
  }
  {didTimeout && <p className='text-red-500'>Sorry, this should have loaded by now, we may have run out of OpenAI quota. You could refresh and try again.</p>}
          </div>
        )}
      </div>
    );
  
  }

  return (
    <div
    // container that limits the width of the content
      className="max-w-2xl mx-auto"
    >
    <div className="flex flex-col items-center justify-center h-screen px-3 ">
      {showConfetti && <ConfettiExplosion />}
      <h1 className="text-2xl font-bold mb-4">{title}</h1>
      <div className="mb-8">
        <p>
          Question {currentQuestion + 1}/10
        </p>
        <p className="text-2xl mb-4">{questions[currentQuestion].question}</p>
        {!showAnswerFeedback ? (
          <div className="grid grid-cols-2 gap-4">
           {Object.entries(questions[currentQuestion].responses).map(([key, value]) => (
              <button
                key={key}
                onClick={() => handleAnswerClick(key)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <span 
                  // reduce the opacity of the key
                  className=""
                >{key}:
                  </span> {value}
              </button>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center"> {/* Add this div */}
            {questionBeingAppealed === currentQuestion && (<>
             {appealSuccess === null && (
                <p className="text-gray-400 text-sm mb-4">
                  Your appeal is being processed...
                </p>

             )
              }
              {appealSuccess === true && (
                <p className="text-green-700 text-2xl mb-4">Your appeal was successful, you have been awarded the point</p>

              )
              }
              {appealSuccess === false && (
                <p className="text-red-700 text-2xl mb-4">Your appeal was unsuccessful.</p>
              )
              }
            
            
            </>
            )}





            {!(questionBeingAppealed === currentQuestion) && (
              
            questions[currentQuestion].answer === selectedAnswer ? (
              <>
                <p className="text-green-700 text-2xl mb-4">Correct!</p>
              </>
            ) : (
              <>
              <p className="text-red-700 text-2xl mb-4">
                Incorrect! The correct answer is {questions[currentQuestion].answer}: {questions[currentQuestion].responses[questions[currentQuestion].answer]}
              </p>
              {false && (
              <p className="text-xs text-gray-400 mb-4">
                (If you believe your answer was correct you can <button className="underline" onClick={handleAppeal}>appeal</button>)

              </p>
              )}
              </>
            )
            )}

            

             {currentQuestion + 1 < questions.length ? (
            <button
              onClick={handleNextQuestion}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              Next Question
            </button>
          ) : (
            currentQuestion<8 ? (
              <>
              <ScaleLoader  color='#ddd' 
              loading={true} size={30} />
              </>)
              :(
            <>
              <p className="text-2xl mt-4">Congratulations! You've completed the quiz!</p>
              <p className="text-xl mt-2">Your final score is {score} out of {questionsAttempted}.</p>
              <button
                onClick={handleRestart}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Start Again
              </button>
            </>
         ) )}
          </div> 
        )}
      </div>
      <div className="mt-8 "> {/* Add styling to the scoring container */}
        
        <div className="flex justify-between"> {/* Add styling to the success percentage */}
          <h3 className=" font-semibold text-gray-700">Success Percentage:</h3>
          <p className=" ml-3">{successPercentage.toFixed(0)}%</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default App;